<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统日志</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form" @submit.prevent>
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler" :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
    </static-table>
  </el-card>

</template>

<script>
import { fetchApiLogs } from "@/api/sys";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "apiLogs",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "id", label: "ID", sortable: true, searchable: true, width: "200", showOverflowTooltip:true },
          { prop: "url", label: "Url", width: "200", sortable: true,  showOverflowTooltip:true },
          { prop: "displayName", label: "DisplayName", sortable: true,  width: "200", showOverflowTooltip:true },
          { prop: "controler", label: "Controler", width: "130", searchable: true, showOverflowTooltip:true },
          { prop: "action", label: "Action", width: "120", searchable: true, showOverflowTooltip:true },
          { prop: "form", label: "Form", width: "130",  searchable: true, showOverflowTooltip:true },
          { prop: "body", label: "Body", width: "200", searchable: true, showOverflowTooltip:true },
          { prop: "result", label: "Result", width: "200",  searchable: true, showOverflowTooltip:true },
          { prop: "ip", label: "IP", width: "90", searchable: true, showOverflowTooltip:true },
          { prop: "userName", label: "UserName", width: "90", searchable: true, },
          { prop: "createDate", label: "CreateDate", width: "180" },
        ],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
    };
  },
  mounted() {
    this.fetchs();
  },
  methods: {
    fetchs() {
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      // search.filters = [];
      // if (this.querys.keyword)
      //   search.filters.push({
      //     name: "account",
      //     value: "%" + this.querys.keyword + "%",
      //     operate: "like", 5
      //   });
      fetchApiLogs(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
  },
};
</script>